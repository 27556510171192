import { useSettingsStore } from "@/stores/settingsStore";

/**
 * @param {import("vue").App} app
 * @param {import("vue-router").Router} router
 * @returns {import("vue").FunctionPlugin}
 */
export const createMatomo = function (app, router) {
  const { settings } = useSettingsStore();
  if (!settings.analytics.matomo.host || !settings.analytics.matomo.siteId) return;
  import("vue-matomo").then((VueMatomo) => {
    VueMatomo.default(app, {
      router,
      host: settings.analytics.matomo.host,
      siteId: settings.analytics.matomo.siteId,
      trackerUrl: `${settings.analytics.matomo.host}/js/`,
      trackerScriptUrl: `${settings.analytics.matomo.host}/js/`,
      requireCookieConsent: true,
      debug: true,
    });
  });
};
