let loaded = false;

function getCookiesConfig(i18n) {
  const $t = i18n.global.t;
  return {
    apiKey: "a8bfebaa4a74a26523e77c855f553a61ae888f9b",
    product: "PRO",
    initialState: "notify",
    necessaryCookies: ["bearer-token", "user-details", "created-event-ids", "ALVATOKEN"],
    notifyDismissButton: false,
    rejectButton: false,
    settingsStyle: "button",
    accessibility: {
      overlay: false,
    },
    text: {
      title: $t("cookie.title"),
      intro: $t("cookie.intro"),
      acceptRecommended: $t("cookie.acceptRecommended"),
      necessaryTitle: $t("cookie.necessaryTitle"),
      necessaryDescription: $t("cookie.necessaryDescription"),
      on: $t("cookie.on"),
      off: $t("cookie.off"),
      notifyTitle: $t("cookie.notifyTitle"),
      notifyDescription: $t("cookie.notifyDescription"),
      accept: $t("cookie.accept"),
      settings: $t("cookie.settings"),
      reject: $t("cookie.reject"),
      acceptSettings: $t("cookie.acceptSettings"),
      rejectSettings: $t("cookie.rejectSettings"),
    },
    position: "LEFT",
    theme: "LIGHT",
    branding: {
      fontColor: "#333",
      backgroundColor: "#FFFFFF",
      fontSizeTitle: "1.2em",
      fontSizeIntro: "1em",
      fontSizeHeaders: "1em",
      fontSize: "0.8em",
      toggleText: "#fff",
      toggleColor: "#222",
      toggleBackground: "#444",
      buttonIcon: "assets/civic-cookie-control/cookie-solid.svg",
      buttonIconWidth: "40px",
      buttonIconHeight: "40px",
      removeIcon: true,
      removeAbout: true,
    },
    excludedCountries: ["all"],
    optionalCookies: [
      {
        name: "analytics",
        label: $t("cookie.analyticsLabel"),
        recommendedState: true,
        description: $t("cookie.analyticsDsc"),
        cookies: [
          "_pk*", // piwik aka matomo
          "*_zldt", // zoho chat
          "zld*", // zoho chat
          "_552d4", // hipay
        ],
        onAccept: function () {
          if (window._paq) {
            window._paq.push(["rememberCookieConsentGiven"]);
          }
        },
        onRevoke: function () {
          if (window._paq) {
            window._paq.push(["forgetCookieConsentGiven"]);
          }
        },
      },
      {
        name: "marketing",
        label: $t("cookie.marketingLabel"),
        recommendedState: true,
        description: $t("cookie.marketingDsc"),
        cookies: [
          "_fbp", // facebook pixel
          "_ga",
          "_gid",
          "_gat",
          "__utma",
          "__utmt",
          "__utmb",
          "__utmc",
          "__utmz",
          "__utmv",
          "_gat*",
          "_gcl_au", // google adsense
          "google_*", // google adsense
        ],
        onAccept: function () {
          if (window.fbq) {
            window.fbq("consent", "grant");
          }
          if (window.gtag) {
            window.gtag("consent", "update", {
              ad_storage: "granted",
            });
          }
        },
        onRevoke: function () {
          if (window.fbq) {
            window.fbq("consent", "revoke");
          }
          if (window.gtag) {
            window.gtag("consent", "update", {
              ad_storage: "denied",
            });
          }
        },
      },
    ],
  };
}

export function createCookieConsent(i18n) {
  return function () {
    const config = getCookiesConfig(i18n);
    const script = document.createElement("script");
    script.src = "https://cc.cdn.civiccomputing.com/9/cookieControl-9.x.min.js";
    script.type = "text/javascript";
    script.onload = function () {
      window.CookieControl.load(config);
      loaded = true;
    };
    document.querySelector("head").append(script);
  };
}

export function open() {
  if (loaded) {
    window.CookieControl.open();
  }
}
export function forceAccept() {
  if (loaded) {
    const cookie = JSON.parse(window.CookieControl.getCookie("CookieControl"));
    if (!cookie || !cookie.interactedWith) {
      window.CookieControl.notifyAccept();
    }
  }
}
export function hide() {
  if (loaded) {
    window.CookieControl.notifyDismiss();
  }
}
