import { createI18n as createI18nFromLib } from "vue-i18n";
import { defaultDocument } from "@vueuse/core";

export const AVAILABLE_LANGUAGES = ["en", "fr"];

export function getPreferedLanguage() {
  for (let lang of usePreferredLanguages().value) {
    let short = lang.slice(0, 2);
    if (AVAILABLE_LANGUAGES.includes(short)) return short;
  }
  return AVAILABLE_LANGUAGES[0];
}

/**
 * https://vue-i18n.intlify.dev/guide/installation.html
 * @returns {Promise<import("vue-i18n").I18n>}
 */
export async function createI18n() {
  const i18n = createI18nFromLib({
    locale: useLocalStorage("locale", getPreferedLanguage()).value,
    fallbackLocale: "en",
    silentFallbackWarn: true,
    fallbackWarn: false,
    missingWarn: false,
    warnHtmlInMessage: "off",
    warnHtmlMessage: false,
    availableLocales: AVAILABLE_LANGUAGES,
    numberFormats: {
      en: {
        currency: {
          style: "currency",
          currency: "EUR",
          currencyDisplay: "symbol",
          maximumSignificantDigits: 10,
        },
        percent: {
          style: "percent",
          useGrouping: false,
        },
      },
      fr: {
        currency: {
          style: "currency",
          currency: "EUR",
          currencyDisplay: "symbol",
          maximumSignificantDigits: 10,
        },
        percent: {
          style: "percent",
          useGrouping: false,
        },
      },
    },
  });
  // @ts-ignore
  await setI18nLanguage(i18n, i18n.global.locale.value);
  // @ts-ignore
  watch(i18n.global.locale, async (locale) => await setI18nLanguage(i18n, locale));
  return i18n;
}

/**
 *
 * @param {import("vue-i18n").I18n} i18n
 * @param {string} locale
 * @returns
 */
export async function setI18nLanguage(i18n, locale) {
  const messages = await import(`../locales/${locale}.json`);
  i18n.global.setLocaleMessage(locale, messages.default);
  useLocalStorage("locale", getPreferedLanguage()).value = locale;
  defaultDocument.documentElement.setAttribute("lang", locale);
  return nextTick();
}
