/**
 * plugins/index.js
 *
 * Included in `./src/main.js`
 */

// Plugins
import { createVuetify } from "./vuetify";
import { createI18n } from "./i18n";
import { createMatomo } from "./matomo";
import { createCookieConsent } from "./cookie-consent";
import { createValidator } from "./validator";
import { createOfetch } from "./ofetch";
import { createSentry } from "./sentry";
import { createStores } from "@/stores";
import { createRouter } from "@/router";
import { trackRouter, default as VueGtag } from "vue-gtag-next";
import { createPhoneInput } from "./phone-input";
import VuetifyUseDialog from "vuetify-use-dialog";

/**
 *
 * @param {import("vue").App<Element>} app
 */
export async function registerPlugins(app) {
  const stores = createStores();
  app.use(stores);
  await useSettingsStore().loadSettings();

  const i18n = await createI18n();
  app.use(i18n);

  const validator = await createValidator(i18n);
  app.use(validator);

  const ofetch = createOfetch(i18n);
  app.use(ofetch);

  const router = createRouter();
  app.use(router);

  const vuetify = createVuetify(i18n);
  app.use(vuetify);
  app.use(VuetifyUseDialog, {
    confirmDialog: {
      dialogProps: {
        width: 560,
      },
      confirmationButtonProps: {
        color: "secondary",
      },
      cancellationButtonProps: {
        variant: "text",
        color: "grey-darken-2",
      },
      cardActionsProps: {
        justify: "center",
        class: "justify-center",
      },
      cardTitleProps: {
        class: "bg-grey-lighten-3",
      },
      cardTextProps: {
        class: "pt-6 text-body-1 text-grey-darken-3",
      },
    },
  });

  const phoneInput = createPhoneInput(i18n);
  app.use(phoneInput);

  if (import.meta.env.PROD) {
    const cookieConsent = createCookieConsent(i18n);
    app.use(cookieConsent);

    const matomo = createMatomo(app, router);
    app.use(matomo);

    app.use(VueGtag, {});
    trackRouter(router);

    const sentry = createSentry(router);
    app.use(sentry);
  }
  return app;
}
