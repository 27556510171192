/* eslint-disable no-unused-vars */
import { useOFetch, useOFetchRaw } from "@/plugins/ofetch";
export * as Models from "../../../types/models";

const _AuthAPI = {
  /** @type {OmitThisParameter<import("../../../../backend/api/public/auth.js").login>}  */
  login: async function login(body = undefined, query = undefined) {
    return await useOFetch(`/n/api/public/login`, { method: "POST", body, query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/auth.js").resetPassword>}  */
  resetPassword: async function resetPassword(body = undefined, query = undefined) {
    return await useOFetch(`/n/api/public/reset-password`, { method: "PATCH", body, query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/auth.js").signUp>}  */
  signUp: async function signUp(body = undefined, query = undefined) {
    return await useOFetch(`/n/api/public/sign-up`, { method: "POST", body, query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/auth.js").authByOrgIdAndEmail>}  */
  authByOrgIdAndEmail: async function authByOrgIdAndEmail(query = undefined) {
    return await useOFetch(`/n/api/public/auth-by-org-id-and-email`, { method: "GET", query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/auth.js").authByOrganizerIdAndEmail>}  */
  authByOrganizerIdAndEmail: async function authByOrganizerIdAndEmail(query = undefined) {
    return await useOFetch(`/n/api/public/auth-by-organizer-id-and-email`, { method: "GET", query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/auth.js").authByIdRoleAndEmail>}  */
  authByIdRoleAndEmail: async function authByIdRoleAndEmail(query = undefined) {
    return await useOFetch(`/n/api/public/auth-by-id-role-and-email`, { method: "GET", query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/auth.js").checkOrganizationId>}  */
  checkOrganizationId: async function checkOrganizationId(organizationId, query = undefined) {
    return await useOFetch(`/n/api/public/check-org-id/${organizationId}`, { method: "GET", query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/auth.js").checkOrganizationFundraisingTeamId>}  */
  checkOrganizationFundraisingTeamId: async function checkOrganizationFundraisingTeamId(fundraisingTeamId, query = undefined) {
    return await useOFetch(`/n/api/public/check-org-fundraising-team-id/${fundraisingTeamId}`, { method: "GET", query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/auth.js").checkVerificationCode>}  */
  checkVerificationCode: async function checkVerificationCode(body = undefined, query = undefined) {
    return await useOFetch(`/n/api/public/check-verification-code`, { method: "POST", body, query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/auth.js").sendVerificationCode>}  */
  sendVerificationCode: async function sendVerificationCode(body = undefined, query = undefined) {
    return await useOFetch(`/n/api/public/send-verification-code`, { method: "POST", body, query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/auth.js").updatePersonalSettings>}  */
  updatePersonalSettings: async function updatePersonalSettings(body = undefined, query = undefined) {
    return await useOFetch(`/n/api/public/personal-settings`, { method: "PATCH", body, query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/auth.js").logout>}  */
  logout: async function logout(body = undefined, query = undefined) {
    return await useOFetch(`/n/api/public/logout`, { method: "POST", body, query });
  },
};
export { _AuthAPI as AuthAPI };
const _EventParticipationsAPI = {
  /** @type {OmitThisParameter<import("../../../../backend/api/public/event-participations.js").getEventParticipationByEventAndOrganization>}  */
  getEventParticipationByEventAndOrganization: async function getEventParticipationByEventAndOrganization(query = undefined) {
    return await useOFetch(`/n/api/public/event-participations/search_by_event_and_organization`, { method: "GET", query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/event-participations.js").getFundraisingEventParticipationByEventAndOrganization>}  */
  getFundraisingEventParticipationByEventAndOrganization: async function getFundraisingEventParticipationByEventAndOrganization(organization_id, event_id, query = undefined) {
    return await useOFetch(`/n/api/public/fundraising/${organization_id}/${event_id}/event-participations`, { method: "GET", query });
  },
};
export { _EventParticipationsAPI as EventParticipationsAPI };
const _EventsAPI = {
  /** @type {OmitThisParameter<import("../../../../backend/api/public/events.js").getEvent>}  */
  getEvent: async function getEvent(event_id, query = undefined) {
    return await useOFetch(`/n/api/public/events/${event_id}`, { method: "GET", query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/events.js").getEventByEventIdOrFundraisingEventId>}  */
  getEventByEventIdOrFundraisingEventId: async function getEventByEventIdOrFundraisingEventId(query = undefined) {
    return await useOFetch(`/n/api/public/events/find-by-event-id-or-fundraising-event-id`, { method: "GET", query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/events.js").getSalesCategories>}  */
  getSalesCategories: async function getSalesCategories(event_id, query = undefined) {
    return await useOFetch(`/n/api/public/events/${event_id}/sales-categories`, { method: "GET", query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/events.js").getEventPaymentMethods>}  */
  getEventPaymentMethods: async function getEventPaymentMethods(event_id, query = undefined) {
    return await useOFetch(`/n/api/public/events/${event_id}/payment-methods`, { method: "GET", query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/events.js").getEventEventTickets>}  */
  getEventEventTickets: async function getEventEventTickets(event_id, query = undefined) {
    return await useOFetch(`/n/api/public/events/${event_id}/event-tickets`, { method: "GET", query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/events.js").findEventDiscountCode>}  */
  findEventDiscountCode: async function findEventDiscountCode(event_id, query = undefined) {
    return await useOFetch(`/n/api/public/events/${event_id}/discount-codes`, { method: "GET", query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/events.js").findSalesDiscountCode>}  */
  findSalesDiscountCode: async function findSalesDiscountCode(event_id, body = undefined, query = undefined) {
    return await useOFetch(`/n/api/public/events/${event_id}/sales-discount-codes`, { method: "POST", body, query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/events.js").updateRegistration>}  */
  updateRegistration: async function updateRegistration(event_id, registration_id, order_id, body = undefined, query = undefined) {
    return await useOFetch(`/n/api/public/events/${event_id}/registration/${registration_id}/${order_id}`, { method: "PATCH", body, query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/events.js").shareTicket>}  */
  shareTicket: async function shareTicket(event_id, registration_id, register_order_id, body = undefined, query = undefined) {
    return await useOFetch(`/n/api/public/events/${event_id}/registration/${registration_id}/share-ticket/${register_order_id}`, { method: "POST", body, query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/events.js").getTaxReceiptStatus>}  */
  getTaxReceiptStatus: async function getTaxReceiptStatus(event_id, order_id, registration_id, query = undefined) {
    return await useOFetch(`/n/api/public/events/${event_id}/registrations/${order_id}/${registration_id}/tax-receipt-status`, { method: "GET", query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/events.js").getTaxReceipt>}  */
  getTaxReceipt: async function getTaxReceipt(event_id, order_id, registration_id, query = undefined) {
    const response = await useOFetchRaw(`/n/api/public/events/${event_id}/registrations/${order_id}/${registration_id}/tax-receipt`, { responseType: "blob", query });
    const href = URL.createObjectURL(response._data);
    const a = document.createElement("a");
    a.href = href;
    a.download = ((response.headers.get("content-disposition") || "").match(/filename="?(.+)"?/) || [])[1] || "tax-receipt";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(href);
    return null;
  },
};
export { _EventsAPI as EventsAPI };
const _FundraisingAPI = {
  /** @type {OmitThisParameter<import("../../../../backend/api/public/fundraising.js").getFundraisingCategories>}  */
  getFundraisingCategories: async function getFundraisingCategories(query = undefined) {
    return await useOFetch(`/n/api/public/fundraising/categories`, { method: "GET", query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/fundraising.js").getFundraisingCharities>}  */
  getFundraisingCharities: async function getFundraisingCharities(query = undefined) {
    return await useOFetch(`/n/api/public/fundraising/charities`, { method: "GET", query });
  },
};
export { _FundraisingAPI as FundraisingAPI };
const _MeAPI = {
  /** @type {OmitThisParameter<import("../../../../backend/api/public/me.js").getMe>}  */
  getMe: async function getMe(query = undefined) {
    return await useOFetch(`/n/api/public/me`, { method: "GET", query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/me.js").updateMe>}  */
  updateMe: async function updateMe(body = undefined, query = undefined) {
    return await useOFetch(`/n/api/public/me`, { method: "PATCH", body, query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/me.js").getMeByCookie>}  */
  getMeByCookie: async function getMeByCookie(query = undefined) {
    return await useOFetch(`/n/api/public/me-by-cookie`, { method: "GET", query });
  },
};
export { _MeAPI as MeAPI };
const _OrganizationsAPI = {
  /** @type {OmitThisParameter<import("../../../../backend/api/public/organizations.js").getOrganization>}  */
  getOrganization: async function getOrganization(id, query = undefined) {
    return await useOFetch(`/n/api/public/organizations/${id}`, { method: "GET", query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/organizations.js").createOrganization>}  */
  createOrganization: async function createOrganization(body = undefined, query = undefined) {
    return await useOFetch(`/n/api/public/organizations`, { method: "POST", body, query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/organizations.js").createOrganizationFromFundraising>}  */
  createOrganizationFromFundraising: async function createOrganizationFromFundraising(body = undefined, query = undefined) {
    return await useOFetch(`/n/api/public/organizations-from-fundraising`, { method: "POST", body, query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/organizations.js").getSalesOrderId>}  */
  getSalesOrderId: async function getSalesOrderId(organization_id, event_id, sales_order_id, query = undefined) {
    return await useOFetch(`/n/api/public/organizations/${organization_id}/event/${event_id}/sales-orders/${sales_order_id}`, { method: "GET", query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/organizations.js").createOrganizationNotification>}  */
  createOrganizationNotification: async function createOrganizationNotification(body = undefined, query = undefined) {
    return await useOFetch(`/n/api/public/new-organization-notification`, { method: "POST", body, query });
  },
};
export { _OrganizationsAPI as OrganizationsAPI };
const _PaymentAPI = {
  /** @type {OmitThisParameter<import("../../../../backend/api/public/payment.js").handlePaymentWebhooks>}  */
  handlePaymentWebhooks: async function handlePaymentWebhooks(body = undefined, query = undefined) {
    return await useOFetch(`/n/api/public/payment/webhooks`, { method: "POST", body, query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/payment.js").getPaymentReturn>}  */
  getPaymentReturn: async function getPaymentReturn(query = undefined) {
    return await useOFetch(`/n/api/public/payment/return`, { method: "GET", query });
  },
};
export { _PaymentAPI as PaymentAPI };
const _RegisterAPI = {
  /** @type {OmitThisParameter<import("../../../../backend/api/public/register.js").createRegisterOrder>}  */
  createRegisterOrder: async function createRegisterOrder(eventId, body = undefined, query = undefined) {
    return await useOFetch(`/n/api/public/events/${eventId}/register`, { method: "POST", body, query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/register.js").payRegisterOrder>}  */
  payRegisterOrder: async function payRegisterOrder(event_id, order_id, body = undefined, query = undefined) {
    return await useOFetch(`/n/api/public/events/${event_id}/register/${order_id}/pay`, { method: "POST", body, query });
  },
};
export { _RegisterAPI as RegisterAPI };
const _RegistrationsAPI = {
  /** @type {OmitThisParameter<import("../../../../backend/api/public/registrations.js").getRegistration>}  */
  getRegistration: async function getRegistration(unique_id, query = undefined) {
    return await useOFetch(`/n/api/public/registrations/${unique_id}`, { method: "GET", query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/registrations.js").getRegistrationQr>}  */
  getRegistrationQr: async function getRegistrationQr(unique_id, query = undefined) {
    const response = await useOFetchRaw(`/n/api/public/registrations/${unique_id}/qr`, { responseType: "blob", query });
    const href = URL.createObjectURL(response._data);
    const a = document.createElement("a");
    a.href = href;
    a.download = ((response.headers.get("content-disposition") || "").match(/filename="?(.+)"?/) || [])[1] || "qr";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(href);
    return null;
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/registrations.js").getRegisterOrder>}  */
  getRegisterOrder: async function getRegisterOrder(order_id, unique_id, query = undefined) {
    return await useOFetch(`/n/api/public/registrations/register-orders/${order_id}/${unique_id}`, { method: "GET", query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/registrations.js").createRegistrationRecovery>}  */
  createRegistrationRecovery: async function createRegistrationRecovery(body = undefined, query = undefined) {
    return await useOFetch(`/n/api/public/registrations/registration-recovery`, { method: "POST", body, query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/registrations.js").downloadTicket>}  */
  downloadTicket: async function downloadTicket(unique_id, query = undefined) {
    const response = await useOFetchRaw(`/n/api/public/registrations/${unique_id}/download-ticket`, { responseType: "blob", query });
    const href = URL.createObjectURL(response._data);
    const a = document.createElement("a");
    a.href = href;
    a.download = ((response.headers.get("content-disposition") || "").match(/filename="?(.+)"?/) || [])[1] || "download-ticket";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(href);
    return null;
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/registrations.js").downloadPaymentReceipt>}  */
  downloadPaymentReceipt: async function downloadPaymentReceipt(order_id, unique_id, query = undefined) {
    const response = await useOFetchRaw(`/n/api/public/registrations/${order_id}/${unique_id}/download-payment-receipt`, { responseType: "blob", query });
    const href = URL.createObjectURL(response._data);
    const a = document.createElement("a");
    a.href = href;
    a.download = ((response.headers.get("content-disposition") || "").match(/filename="?(.+)"?/) || [])[1] || "download-payment-receipt";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(href);
    return null;
  },
};
export { _RegistrationsAPI as RegistrationsAPI };
const _SettingsAPI = {
  /** @type {OmitThisParameter<import("../../../../backend/api/public/settings.js").getSettings>}  */
  getSettings: async function getSettings(query = undefined) {
    return await useOFetch(`/n/api/public/settings`, { method: "GET", query });
  },
};
export { _SettingsAPI as SettingsAPI };
const _SmtpAPI = {
  /** @type {OmitThisParameter<import("../../../../backend/api/public/smtp.js").handleSMTPWebhooks>}  */
  handleSMTPWebhooks: async function handleSMTPWebhooks(body = undefined, query = undefined) {
    return await useOFetch(`/n/api/public/smtp/webhooks`, { method: "POST", body, query });
  },
};
export { _SmtpAPI as SmtpAPI };
const _UsersAPI = {
  /** @type {OmitThisParameter<import("../../../../backend/api/public/users.js").getUser>}  */
  getUser: async function getUser(query = undefined) {
    return await useOFetch(`/n/api/public/users/search`, { method: "GET", query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/users.js").getEmailPreferences>}  */
  getEmailPreferences: async function getEmailPreferences(query = undefined) {
    return await useOFetch(`/n/api/public/users/email-preferences`, { method: "GET", query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/users.js").updateEmailPreferences>}  */
  updateEmailPreferences: async function updateEmailPreferences(body = undefined, query = undefined) {
    return await useOFetch(`/n/api/public/users/email-preferences`, { method: "PATCH", body, query });
  },
  /** @type {OmitThisParameter<import("../../../../backend/api/public/users.js").createContactSupport>}  */
  createContactSupport: async function createContactSupport(body = undefined, query = undefined) {
    return await useOFetch(`/n/api/public/users/contact-support`, { method: "POST", body, query });
  },
};
export { _UsersAPI as UsersAPI };
