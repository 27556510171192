// Utilities
import { defineStore } from "pinia";

export const useContextStore = defineStore("context", () => {
  const organizer = ref();
  const event = ref();
  const organization = ref();
  const event_participation = ref();

  return {
    organizer,
    event,
    organization,
    event_participation,
  };
});
