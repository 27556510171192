import { ofetch } from "ofetch";
import AppError from "@/utils/AppError";
import FormError from "@/utils/AppError";
import { defaultWindow } from "@vueuse/core";
import { stringify } from "qs";
import { hasInjectionContext } from "vue";

/**
 * @returns {import("vue").Plugin}
 */
export function createOfetch(i18n) {
  const { sid } = useSettingsStore();
  const messageStore = useMessageStore();
  const authStore = useAuthStore();
  const _ofetch = ofetch.create({
    headers: { Sid: sid },
    credentials: "include",
    async onRequest(conf) {
      if (authStore.auth) {
        conf.options.headers["Authorization"] = `Bearer ${authStore.auth.access_token}`;
      }
      if (conf.options.query) {
        const queryString = stringify(conf.options.query);
        if (queryString) {
          conf.request += (conf.request.toString().indexOf("?") === -1 ? "?" : "&") + queryString;
        }
        delete conf.options.query;
      }
    },
    async onRequestError({ error }) {
      messageStore.error(i18n.global.t("errors.server.notreachable"));
      return Promise.reject(error);
    },
    async onResponseError({ response }) {
      const status = response.status;
      const errorData = response._data.error || response._data;
      if (status === 401) {
        if (console && console.log) console.log("401 Unauthorized", errorData);
        useBrowserLocation().value.href = "/login";
      } else if (status === 400) {
        if (console && console.log) console.log("Bad request", errorData);
        if (errorData.name === "AppError") {
          messageStore.error(i18n.global.t(errorData.message, errorData.params));
        } else if (errorData.name !== "FormError") {
          messageStore.error(i18n.global.t("errors.server.validation"));
        }
      } else if (status === 500) {
        if (console && console.log) console.log("Technical error", errorData);
        messageStore.error(i18n.global.t("errors.server.technical"));
      } else if (status === 404) {
        if (console && console.log) console.log("Not found error", errorData);
        messageStore.error(i18n.global.t("errors.server.notFound"));
      } else {
        if (console && console.log) console.log("Unexpected error", status, errorData);
        messageStore.error(i18n.global.t("errors.server.unexpected"));
      }
      let returnedError = errorData;
      if (returnedError && !(returnedError instanceof Error)) {
        if (returnedError.name === "AppError") {
          returnedError = new AppError(returnedError.message, returnedError.params);
        } else if (returnedError.name === "FormError") {
          returnedError = new FormError(returnedError.message, returnedError.params);
        }
      }
      throw returnedError;
    },
  });
  if (!import.meta.env.SSR) {
    defaultWindow["ofetch"] = _ofetch;
  }
  _ofetch["install"] = function (app) {
    app.provide("ofetch", this);
  };
  // @ts-ignore
  return _ofetch;
}

/**
 *
 * @param {Parameters<ofetch>[0]} url
 * @param {Parameters<ofetch>[1]} options
 * @returns {Promise<any>}
 */
export const useOFetch = async function (url, options) {
  return await ((hasInjectionContext() && inject("ofetch")) || defaultWindow["ofetch"])(url, options);
};

/**
 *
 * @type {typeof ofetch.raw<any,any>}
 */
export const useOFetchRaw = async function (url, options) {
  return await ((hasInjectionContext() && inject("ofetch")) || defaultWindow["ofetch"]).raw(url, options);
};
