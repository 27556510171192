import "flag-icons/css/flag-icons.min.css";
import "v-phone-input/dist/v-phone-input.css";
import { createVPhoneInput } from "v-phone-input";

export function createPhoneInput(i18n) {
  return createVPhoneInput({
    countryIconMode: "svg",
    invalidMessage: (options) => i18n.global.t("errors.validator.phone_number", options),
  });
}
